<template>
  <div class="rule-table">
    <!--头部-->
    <el-form>
      <el-row
        justify="center"
        class="first-row"
        v-for="(headerItem,j) in SelfRuleData.slice(0,1)"
        :key="j + 'first'"
      >
        <el-col
          class="col-box isfirst-row header one-col"
          :span="getColNumber()"
        >账户类型/品种
        </el-col>
        <el-col
          class="col-box isfirst-row header"
          v-for="(headerItem,k) in headerItem.symbolGroup"
          :key="k"
          :span="getColNumber()"
        >{{getHeaderItem(headerItem)}}
        </el-col>
      </el-row>
      <el-row
        justify="center"
        class="second-row"
        v-for="(item,index) in SelfRuleData"
        :key="index"
      >
        <el-col
          class="col-box"
          :span="getColNumber()"
        >{{item.mt4GroupNamme}}
        </el-col>
        <el-col
          class="col-box"
          v-for="(colItem,i) in item.symbolGroup"
          :key="i"
          :span="getColNumber()"
        >
          <el-form-item class="form-item">
            <span v-if="isReadonly || colItem.max == -1">
              {{getReadonlyText(colItem)}}
              <span>{{getSuffix(colItem.suffix,colItem)}}</span>
            </span>
            <div class="input-box pr" v-else>
              <el-input
                maxLength="6"
                :placeholder="getPlaceholder(colItem.max)"
                v-model="colItem.value"
                @blur.native="blurHandle(colItem)"
                :class="validateClass(colItem,index,i)"
              ></el-input>
              <span class="suffix">{{getSuffix(colItem.suffix,colItem)}}</span>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div v-if="!ruleData.length" class="no-data">暂无数据</div>
  </div>
</template>

<script>
  import { maxOneFloatOrNone } from '@/utils/validate'
  export default {
    name: 'RuleTable',
    props: {
      ruleData: {
        type: Array,
        default: [
          /*{
            accountNamme: 'STD',
            symbolGroup: [
              {
                name: 'Forex',
                value: 5,
                max: 5,
                suffix: '$/lot',
                tips: '≤5'
              },
              {
                name: 'GOLD',
                value: 3,
                max: 3,
                suffix: 'pips/lot',
                tips: '≤3'
              },
              {
                name: 'SUVKER',
                value: 2,
                max: 2,
                suffix: 'pips/lot',
                tips: '≤2'
              },
              {
                name: 'Oil',
                value: 0.2,
                max: 0.2,
                suffix: '$/lot',
                tips: '≤0.2'
              },
              {
                name: 'INDEX',
                value: 1,
                max: 1,
                suffix: '$/lot',
                tips: '≤1'
              },
              // todo,模拟一下数据
              {
                name: 'Stock',
                value: 1,
                max: 1,
                suffix: '%',
                tips: '≤1'
              }
            ]
          },*/
        ]
      },
      isReadonly:{
        type: Boolean,
        default: false,
      },
      fatherRuleData:{
        type: Array,
        default(){
          return []
        }
      },
    },
    data() {
      return {
        SelfRuleData: JSON.parse(JSON.stringify(this.ruleData)),
      };
    },
    watch:{
      ruleData(newVal){
        this.SelfRuleData = JSON.parse(JSON.stringify(newVal.slice(0)));
      },
    },
    methods: {
      clearCom(dataList){
        for (let i = 0; i < dataList.length; i++) {
          for (let j = 0; j < dataList[i].symbolGroup.length; j++) {
            dataList[i].symbolGroup[j].value = '';
          }
        }
      },
      getSuffix(suffix,colItem){
        if(colItem.max == -1){
          return '';
        }
        if(suffix === 1){
          return '$' + '/lot';
        }else if(suffix === 3){
          return 'pips' + '/lot'
        }else if(suffix === 4){
          return '%';
        }
      },
      getColNumber(){
        /*let colNumber = 0;
        switch (this.SelfRuleData[0].symbolGroup.length) {
          case 4:
            colNumber = 5;
            break;
          case 4:
            colNumber = 4;
            break;
        }*/
        return 7;
      },
      getHeaderItem(headerItem){
        return headerItem.name;
      },
      getColItem(colItem){
        return colItem.value;
      },
      getPlaceholder(max){
        // max value 都是-1就显示/
        if(max == -1){
          console.log(max);
          return '/';
        }
        // 修改代理的佣金规则不需要显示<=
        if(this.fatherRuleData.length){
          console.log(max);

          return max;
        }else {
          return '≤' + max;
        }
      },
      blurHandle(colItem){
        if(!this.fatherRuleData.length){
          if(colItem.value > colItem.max){
            this.$message({
              type: 'warning',
              message: `最高可设${max}`
            });
            return;
          }
        }
      },
      validateClass(colItem,index,i){
        if(!this.fatherRuleData.length){
          if(colItem.value > colItem.max){
            return 'nopass';
          }else {
            return '';
          }
        }else {
          if(colItem.value > this.fatherRuleData[index].symbolGroup[i].value){
            return 'nopass';
          }else {
            return '';
          }
        }
      },
      validateTable(){
        // 现在SelfRuleData没有数据所以报错了,到时候联调再看 todo
        let pass = true;
        let reg = /^[0-9]+([.][0-9]{1}){0,1}$/;
        let regStock = /^[0-9]+([.][0-9]{0,2}){0,2}$/; //
        let regCent = /^[0-9]+([.][0-9]{0,4}){0,4}$/; //
        for (let i = 0; i < this.SelfRuleData.length; i++) {
          if(!pass){
            break;
          }
          for (let j = this.SelfRuleData[i].symbolGroup.length - 1; j >= 0; j--) {

            // 没有规则的情况下不用验证,max为-1,
            if(this.SelfRuleData[i].symbolGroup[j].max !== -1){
                // 非空验证
              if(this.SelfRuleData[i].symbolGroup[j].value === ''){
                pass = false;
                this.$message.error(`请填写${this.SelfRuleData[i].mt4GroupNamme} / ${this.SelfRuleData[i].symbolGroup[j].name}`);
                break;
              }
              // 验证最多只能有一位小数
              // Cent支持两位小数 todo
              if(this.SelfRuleData[i].mt4GroupNamme !== 'Cent'){
                if(reg.test(this.SelfRuleData[i].symbolGroup[j].value) == false){
                  this.$message.error(`Cent账户允许两位小数,其他账户允许一位小数`);
                  pass = false;
                  break;
                }
              }else {
                if(this.SelfRuleData[i].symbolGroup[j].name == 'Stock'){
                  if(regStock.test(this.SelfRuleData[i].symbolGroup[j].value) == false){
                    this.$message.error(`Cent账户Stock类型允许两位小数`);
                    pass = false;
                    break;
                  }
                }else {
                  if(regCent.test(this.SelfRuleData[i].symbolGroup[j].value) == false){
                    this.$message.error(`Cent账户允许四位小数,其他账户允许一位小数`);
                    pass = false;
                    break;
                  }
                }
              }


              // 添加佣金规则
              if(!this.fatherRuleData.length){
                if(this.SelfRuleData[i].symbolGroup[j].value > this.SelfRuleData[i].symbolGroup[j].max){
                  pass = false;
                  this.$message({
                    type: 'error',
                    message: `${this.SelfRuleData[i].mt4GroupNamme}/${this.SelfRuleData[i].symbolGroup[j].name}最高可设${this.SelfRuleData[i].symbolGroup[j].max}`
                  });
                  break;
                }

              }else {
                // 编辑佣金规则
                if(this.SelfRuleData[i].symbolGroup[j].value > this.fatherRuleData[i].symbolGroup[j].value){
                  pass = false;
                  this.$message({
                    type: 'error',
                    message: `${this.SelfRuleData[i].mt4GroupNamme}/${this.SelfRuleData[i].symbolGroup[j].name}最高可设${this.fatherRuleData[i].symbolGroup[j].max}`
                  });
                  break;
                }
              }
            }
          }
        }
        if(!pass){
          return;
        }else {
          let copySelfRuleData = JSON.parse(JSON.stringify(this.SelfRuleData));
          for (let i = 0; i < copySelfRuleData.length; i++) {

            for (let j = copySelfRuleData[i].symbolGroup.length - 1; j >= 0; j--) {

              if(copySelfRuleData[i].symbolGroup[j].name === 'Stock' ){
                if(copySelfRuleData[i].symbolGroup[j].max){
                  copySelfRuleData[i].symbolGroup[j].max = copySelfRuleData[i].symbolGroup[j].max / 100;
                }

                if(copySelfRuleData[i].symbolGroup[j].value){
                  copySelfRuleData[i].symbolGroup[j].value = copySelfRuleData[i].symbolGroup[j].value / 100;
                }
              }

              // 没有规则的情况下不用填写,value赋值-1
              if(copySelfRuleData[i].symbolGroup[j].max == -1){
                copySelfRuleData[i].symbolGroup.splice(j,1);
              }
            }
          }
          this.$emit('submit',copySelfRuleData);
        }
      },
      getReadonlyText(item){
        if(item.max == -1){
          return '/'
        }
        return `${item.value}`;
      },
    }
  };
</script>
<style lang="less">
  .rule-table {
    .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
    .el-form-item__content {
      line-height: 50px;
    }
    .nopass {
      .el-input__inner {
        color: red;
      }
    }
  }
</style>
<style scoped lang="less">
  .rule-table {
    .table-title {
      margin-bottom: 10px;
    }

    .col-box {
      height: 56px;
      line-height: 56px;
      text-align: center;
      border: 1px solid #F0F0F0;
      border-top: none;
      border-right: none;
      &.header {
        background-color: #f5f5f5;
        border-right: none;
        border-left: none;
        border-bottom: none;
        font-weight: bold;
      }
      &.one-col {
        border-left: 1px solid #F0F0F0;
      }
      &.isfirst-row {
        border-top: 1px solid #F0F0F0;
      }
      &:not(:first-of-type){
        border-left: none;
      }
      &:last-of-type {
        border-right: 1px solid #F0F0F0;
      }
      .form-item {
        padding: 5px;
        .suffix {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
        }

      }
    }
    .no-data {
      text-align: center;
      font-size: 20px;
      padding: 40px 0;
    }
  }
</style>
<style lang="less">
.el-col-7 {
  max-width: 14.28571429%;
  flex: 0 0 14.28571429%;
}
</style>
