<template>
  <div class="head-container">
    <!-- 搜索 -->

    <el-select
      @change="selectChangeHandle"
      v-model="query.createType"
      clearable placeholder="创建类型" class="filter-item"
      style="width: 130px">
      <el-option
        v-for="item in createTypeOptions"
        :key="item.key"
        :label="item.display_name"
        :value="item.key"
      />
    </el-select>
    <el-select
        @change="selectChangeHandle"
        v-model="query.linkType"
        clearable
        placeholder="链接类型"
        class="filter-item"
        style="width: 130px"
    >
      <el-option
          v-for="item in linkTypeOptions"
          :key="item.key"
          :label="item.display_name"
          :value="item.key"
      />
    </el-select>
    <el-input v-model="query.custInfo" clearable placeholder="创建代理姓名/ID" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary"
               icon="el-icon-close" @click="clearHandle">清空</el-button>

    <el-dropdown>
      <span class="el-dropdown-link filter-item">
        <el-button
            size="mini" type="primary"
        >
          添加
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-if="checkPermission(['ADMIN','TMerchantCommissionLevel_ALL','TMerchantCommissionLevel_CREATE'])"
        >
          <div
              @click="addMerchantLink"
          >代理链接</div>
        </el-dropdown-item>
        <el-dropdown-item
        >
          <div
              @click="addCustomerLink"
          >直客链接</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <add-merchant-links
        :dialogShow="addMerchantLinksDialogShow"
        @close="close"
        @do-success="doSuccess"
    ></add-merchant-links>
  </div>
</template>

<script>
import addMerchantLinks from "@/components/ib/merchantinfo/addMerchantLinks";
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  components:{
    addMerchantLinks
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      createTypeOptions: [
        {
          key: 1,
          display_name: '系统创建'
        },
        {
          key: 2,
          display_name: '代理创建'
        },
      ],
      linkTypeOptions: [
        {
          key: 1,
          display_name: '代理链接'
        },
        {
          key: 2,
          display_name: '直客链接'
        },
      ],

      addMerchantLinksDialogShow: false,
    }
  },
  methods: {
    checkPermission,
    selectChangeHandle(){
      this.$parent.init();
    },
    toQuery() {
      this.$parent.page = 0;
      this.$parent.init();
    },
    clearHandle(){
      this.$emit('clear')
    },
    addMerchantLink(){
      this.addMerchantLinksDialogShow = true;
    },
    close(){
      this.addMerchantLinksDialogShow = false;
    },
    doSuccess(){
      this.addMerchantLinksDialogShow = false;
      this.$emit('do-success');
    },
    addCustomerLink(){
      this.$emit('add-customerlink')
    }
  }
}
</script>
