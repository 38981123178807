<template>
<el-dialog
    title="返佣规则"
    :visible="dialogShow"
    :before-close="handleClose"
    width="1200px"
    append-to-body
    class="rule-dialog"
>
  <div class="table-outer">
    <el-table
        v-loading="loading"
        :data="tableData"
        size="small"
        stripe
        style="width: 100%;"
    >
      <el-table-column prop="id" label="NO." min-width="50%"/>
      <el-table-column prop="ruleName" label="规则名称"/>
      <el-table-column prop="rewardType" label="奖励类型">
        <template slot-scope="scope">
          <span>{{ parseRewardType(scope.row.rewardType) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="custId" label="创建代理"/>

      <el-table-column prop="commType" label="返佣模式">
        <template slot-scope="scope">
          <span>{{ scope.row.commType === 1 ? '内佣' : '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="levelName" label="适用级别"/>
      <el-table-column prop="tradeCat" label="交易种类">
        <template slot-scope="scope">
          <span>{{ getTradeCat(scope.row.catName) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mtGroup" label="MT分组">
        <template slot-scope="scope">
          <span>{{ getMtGroup(scope.row.groupName) }}</span>
        </template>
      </el-table-column>
      <el-table-column   label="返佣规则" min-width="150%">
        <template slot-scope="scope" >
          <pre >{{ parseRules(scope.row) }}</pre>
        </template>
      </el-table-column>
      <el-table-column prop="isvalid" label="状态">
        <template slot-scope="scope">
          <span>{{ parseIsValid(scope.row.isvalid) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</el-dialog>
</template>

<script>
import { parseTime } from '@/utils/index'
import { parseMTGroup,parseCategorys,parseIsValid,parseIndirectCommType,parseDirectCommType,parseCommType,parseRewardType } from '@/views/pt/ib/utils/index'
import { initData } from '@/api/data'
export default {
  name: 'ruleDialog',
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    ibLvlId:{
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      url: 'crm/tMerchantCommissionRules',
      tableData: [],
      loading: false,
    }
  },
  watch:{
    ibLvlId(val){
      if(val){
        this.queryTableData();
      }
    }
  },
  methods: {
    parseTime,
    parseMTGroup,
    parseCategorys,
    parseIsValid,
    parseIndirectCommType,
    parseDirectCommType,
    parseCommType,
    parseRewardType,
    handleClose() {
      this.$emit('close');
    },
    queryTableData(){
      this.loading = true;
      let params = {
        ibLvl: this.ibLvlId,
      }
      initData(this.url, params).then(res => {
        this.tableData = res.content;
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      })
    },
    getTradeCat(tradeCat){
      if(!tradeCat) return '';
      return tradeCat;
    },
    getMtGroup(mtGroup){
      if(!mtGroup) return '';
      return mtGroup;
    },
    parseRules(rule){
      var result=''
      switch(rule.directCommType){
        case 1:
          result='直接客户:$'+rule.directCommValue+'/手\n'
          this.dirrule='$'+rule.directCommValue
          break;
        case 2:
          result='直接客户:交易量*'+rule.directCommValue+'%\n'
          this.dirrule='%'+rule.directCommValue
          break;
        case 3:
          result='直接客户:'+rule.directCommValue+'pips/手\n'
          this.dirrule='pips$'+rule.directCommValue
          break;
      }
      if(rule.rewardType!=2){
        switch(rule.indirectCommType){
          case 1:
            result+='间接客户:'+ '上级标准-下级标准\n'
            break;
          case 2:
            result+='间接客户:$'+rule.indirectCommValue+'/手'
            break;
          case 3:
            result+='间接客户:返佣总额*'+rule.indirectCommValue+'%\n'
            break;
          case 4:
            result+='间接客户:'+rule.indirectCommValue+'/pips/手\n'
            break;
        }
      }
      return result
    },
  }
}
</script>

<style lang="less" scoped>
.rule-dialog {
  .table-outer {
    min-height: 300px;
    max-height: 650px;
    overflow-y: auto;
  }
}
</style>