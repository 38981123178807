import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'crm/tMerchantInfo',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'crm/tMerchantInfo/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: '/api/tMerchant/updateMerInfo',
    method: 'post',
    data
  })
}

export function audit(data) {
  return request({
    url: '/api/tMerchantInfo/merApplyAudit',
    method: 'get',
    params: data
  })
}

export function setCustLvlOuter(data) {
  return request({
    url: '/crm/setCustLvlOuter',
    method: 'get',
    params: data
  })
}

/**
 * 客户升级为代理
 * 必须参数：
 * type  升级类型 1- 升级为主代理 2-升级为子代理
 custId ： 直客的id
 belongId ： 上级的id （如果升级主代理则为客户经理的id，如果升级为子代理则为上级代理的id）
 levelId ： 代理级别id
 * @param data
 */
export function upgradeCustomers(data) {
  return request({
    url: '/crm/upgradeCustomers',
    method: 'put',
    data
  })
}

export function updateCustInfoConfig(data) {
  return request({
    url: '/crm/updateCustInfoConfig',
    method: 'put',
    data
  })
}

/**
 * 搜索代理
 * @param data: {basicInfo:, page: 0,size: 1000 }
 * @returns {*}
 */
export function queryMerchantList(data) {
  return request({
    url: '/crm/tCustInfo',
    method: 'get',
    params: data
  })
}

/**
 *（传custId参数）获取用户启用的MT4大分组信息
 * @param data
 */
export function queryAgentGroupList(data) {
  return request({
    url: '/crm/validMt4Group',
    method: 'get',
    params: data
  })
}


/**
 *外佣的分组数据
 * @param data
 */
export function queryGroupDetailAll(data) {
  return request({
    url: '/crm/queryGroupDetailAll',
    method: 'get',
    params: data
  })
}

// 外佣设置的查询
export function queryGroupDetail(data) {
  return request({
    url: '/crm/tCustGroup',
    method: 'get',
    params: data
  })
}

// 查看用户默认开户分组配置,替换上面的那个
export function queryDefaultGroupDetail(data) {
  return request({
    url: '/crm/defaultCustGroups',
    method: 'get',
    params: data
  })
}


// 外佣设置的保存
export function saveGroupDetail(data) {
  return request({
    url: '/crm/tCustGroup',
    method: 'put',
    data
  })
}

// 添加直客邀请链接
export function addCustomerLink(data){
  return request({
    url: '/crm/custLink',
    method: 'post',
    data
  })
}

// 编辑直客邀请链接
export function editCustomerLink(data){
  return request({
    url: '/crm/custLink',
    method: 'put',
    data
  })
}

//  后台查询代理级别返佣规则
/**
 * custId	用户id	N	整型
 * levelId	代理级别id	N	整型
 * @param data
 * @returns {*}
 */
export function queryMerchantCommissonRule(data){
  return request({
    url: '/crm/queryTMerchantCommissionLevelsRule',
    method: 'get',
    params: data
  })
}

//  后台增加代理级别
/**
 * name	代理级别名称	Y	字符串
 * custId	代理id	Y	整型
 * newRules	代理级别返佣规则集	Y	数组
 * @param data
 * @returns {*}
 */
export function addMerchantLevel(data){
  return request({
    url: '/crm/commissionLevel',
    method: 'post',
    data: data
  })
}

// crm/commissionLevelsRule

//  后台编辑代理级别
/**
 * levelId	代理级别id	Y	整型
 * dataList	请求参数集	Y	数组
 * @param data
 * @returns {*}
 */
export function editMerchantLevel(data){
  return request({
    url: '/crm/commissionLevelsRule',
    method: 'put',
    data: data
  })
}

export function activeUserLink(custId){
  return request({
    url: `/crm/auth/activeReg/${custId}`,
    method: 'put',
  })
}
