<template>
  <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-position="labelPosition"
      label-width="120px"
  >
    <el-form-item
        label="代理"
        prop="agencyId"
    >
      <el-select
          style="width: 100%"
          v-model="form.agencyId"
          filterable
          remote
          reserve-keyword
          :disabled="isEdit"
          :remote-method="getAgencyNameOption"
          :loading="agencyNameLoading"
          placeholder="姓名/ID"
      >
        <el-option
            v-for="item in agencyNameOption"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
        label="级别名称"
        prop="levelName"
    >
      <el-input
          :disabled="isEdit"
          placeholder="请输入"
          v-model="form.levelName"
          maxlength="10"
          show-word-limit
      ></el-input>
    </el-form-item>
    <el-form-item
        v-if="form.agencyId"
        label="返佣规则"
    >
      <!--        添加返佣规则组件 todo-->
      <RuleTable
          :ruleData="templateList"
          ref="ruletable"
          @submit="submitHandle"
      ></RuleTable>
    </el-form-item>
    <el-form-item
      v-if="isEdit"
      label="是否锁定"
      prop="modifyRuleFlag"
    >
      <el-radio
        v-model="form.modifyRuleFlag"
        :label="0"
      >锁定</el-radio>
      <el-radio
          v-model="form.modifyRuleFlag"
          :label="1"
      >不锁定</el-radio>
    </el-form-item>
  </el-form>
</template>

<script>
import { queryMerchantList, queryMerchantCommissonRule } from "@/api/ib/ibMerchantInfo";
import RuleTable from "@/components/ib/merchantinfo/RuleTable";
export default {
  name: "merchantLinkForm",
  components:{
    RuleTable
  },
  props: {
    isEdit:{
      type: Boolean,
      default: false,
    },
    levelId:{
      type: Number,
    },
    formData:{
      type: Object,
      default(){
        return {
          custId: '',
          levelName: '',
          modifyRuleFlag: 1,
        }
      }
    },
  },

  data() {
    return {
      form: {
        agencyId: '',
        levelName: '',
        modifyRuleFlag: 1,
      },
      rules:{
        agencyId: [
          { required: true, message: '请选择代理', trigger: 'change' },
        ],
        levelName:[
          { required: true, message: '请输入级别名称', trigger: 'blur' },
        ],
        modifyRuleFlag:[
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      labelPosition: 'right',
      templateList: [],
      agencyNameOption: [],
      agencyNameLoading: false,
      copySelfRuleData: [], // 返佣规则处理过的数据
    }
  },
  watch:{
    'form.agencyId'(newVal,oldVal){
      if(newVal){
        this.getTemplateList();
      }
    },
    formData:{
      handler(newVal,oldVal){

        if(newVal){
          this.form.agencyId = newVal.custId;
          this.form.levelName = newVal.levelName;
          this.form.modifyRuleFlag = newVal.modifyRuleFlag;
        }
      },
      deep:true,
    },
    levelId(newVal){
      // 请求返佣规则
      if(newVal){
        this.getTemplateList();
      }
    },
  },
  created(){
    if(this.formData.custId){
      this.form.agencyId = this.formData.custId;
      this.form.levelName = this.formData.levelName;
      this.form.modifyRuleFlag = this.formData.modifyRuleFlag;
    }
  },
  methods:{
    handleTemplateListToNull(templateList){
      if (templateList.length) {
        let emptList = JSON.parse(JSON.stringify(templateList));
        for (let i = 0; i < emptList.length; i++) {
          for (let j = 0; j < emptList[i].symbolGroup.length; j++) {
            if(emptList[i].symbolGroup[j].name === 'Stock'){

              if(emptList[i].symbolGroup[j].max){
                emptList[i].symbolGroup[j].max = emptList[i].symbolGroup[j].max * 100;
              }
            }
            emptList[i].symbolGroup[j].value = '';
          }
        }
        return emptList;
      } else {
        return [];
      }
    },
    getTemplateList(){
      let params = {
        custId: this.form.agencyId
      }
      if(this.isEdit){
        params.levelId = this.levelId;
        delete params.custId;
      }
      queryMerchantCommissonRule(params).then((res) => {
        if(this.isEdit){
          queryMerchantCommissonRule({
            custId: this.form.agencyId
          }).then((res2) => {
            this.templateList = this.handlerTwoRule(res,res2);
          })

        }else {
           this.templateList = this.handleTemplateListToNull(res);
        }
      })
    },
    handlerTwoRule(res1,res2){
      for (let i = 0; i < res1.length; i++) {
        for (let j = 0; j <res1[i].symbolGroup.length ; j++) {

          res1[i].symbolGroup[j].max = res2[i].symbolGroup[j].max;

          if(res1[i].symbolGroup[j].name === 'Stock'){
            if(res1[i].symbolGroup[j].value){
              res1[i].symbolGroup[j].value = res1[i].symbolGroup[j].value * 100;
            }
            if(res1[i].symbolGroup[j].max){
              res1[i].symbolGroup[j].max = res1[i].symbolGroup[j].max * 100;
            }
          }
        }
      }
      return res1;
    },
    submitHandle(copySelfRuleData){
      // 规则组件emit
      this.copySelfRuleData = copySelfRuleData;
      this.$emit('submit');
    },
    reset(){
      this.form = {
        agencyId:'',
        name: '',
        modifyRuleFlag: 1,
      };
    },
    getAgencyNameOption(query){
      if (query !== '') {
        this.agencyNameLoading = true;
        queryMerchantList({
          custTypeStr:'1,2',
          page: 0,
          size: 1000,
          basicInfo: query,
        }).then((res) => {
          this.agencyNameLoading = false;
          this.agencyNameOption = res.content;
        });
      } else {
        this.agencyNameOption = [];
      }
    },
    getAddAjaxParams(){
      return {
        name: this.form.levelName,
        custId: this.form.agencyId,
        newRules: this.copySelfRuleData,
      }
    },
    getEditAjaxParams(){
      return {
        modifyRuleFlag: this.form.modifyRuleFlag,
        dataList: this.copySelfRuleData,
      }
    },
  },
}
</script>

<style scoped>

</style>
