<template>
  <el-dialog
      :append-to-body="true"
      :visible="dialogShow"
      title="添加代理链接"
      width="1200px"
      :before-close="cancel"
      class="addlinks-dialog"
  >
    <merchant-link-form
        ref="merchantlinkform"
        @submit="submitHandle"
    ></merchant-link-form>
    <div class="dialog-footer" slot="footer">
<!--      <el-button type="text" @click="cancel">取消</el-button>-->
      <el-button
          :loading="loading"
          type="primary"
          @click="sureHandle"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addMerchantLevel } from '@/api/ib/ibMerchantInfo';
import { mapGetters } from 'vuex'
import merchantLinkForm from "@/components/ib/merchantinfo/merchantLinkForm";

export default {
  name: 'addMerchantLinks',
  components:{
    merchantLinkForm,
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },

  },
  data() {
    return {
      loading: false,

    }
  },
  created(){
  },
  watch:{
    dialogShow(newVal){
      if(newVal){

      }
    },
    formData:{
      handler(newVal){

      },
      deep:true,
    },

    curLinkId(newVal){

    },
  },
  computed: {
  },
  methods: {
    close(){
      this.$refs.merchantlinkform.$refs.form.clearValidate();
      this.$emit('close');
    },

    cancel(){
      this.close();
    },
    sureHandle() {
      this.$refs.merchantlinkform.$refs.form.validate((valid) => {
        if(valid){
          console.log('pass');
          // ajax
          this.$refs.merchantlinkform.$refs.ruletable.validateTable();

        }else {
          console.log('no-pass');
        }
      })
    },
    submitHandle(){
      let params = this.$refs.merchantlinkform.getAddAjaxParams();
      addMerchantLevel(params).then((res) => {
        this.$refs.merchantlinkform.$refs.form.clearValidate();
        this.$notify({
          message: '添加成功',
          type: 'success'
        });
        this.$emit('do-success');
        this.$refs.merchantlinkform.reset();
      })
    }
  }
}
</script>

<style lang="less" scoped>
.addlinks-dialog {
  .el-select {
    width: 100%;
  }
}
</style>