import { render, staticRenderFns } from "./addMerchantLinks.vue?vue&type=template&id=292b1b04&scoped=true&"
import script from "./addMerchantLinks.vue?vue&type=script&lang=js&"
export * from "./addMerchantLinks.vue?vue&type=script&lang=js&"
import style0 from "./addMerchantLinks.vue?vue&type=style&index=0&id=292b1b04&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "292b1b04",
  null
  
)

export default component.exports