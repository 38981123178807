<template>
  <div class="app-container">
    <eHeader
        @clear="clear"
        :query="query"
        @add-customerlink="addCustomerlink"
        @do-success="doAddMerchantSuccess"
    />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" stripe>
      <el-table-column prop="linkNo" label="NO."/>
      <el-table-column prop="custType" label="创建类型">
        <template slot-scope="scope">
          <span>{{ getType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="custId" label="代理">
        <template slot-scope="scope">
          <div>{{scope.row.custName}}</div>
          <div>{{scope.row.custId}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="levelName" label="链接类型">
        <template slot-scope="scope">
          <span>{{getLinkType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="levelName" label="级别名称"/>
      <el-table-column prop="custCount" label="代理/客户数量">
        <template slot-scope="scope">
          <span @click="toList(scope.row)" class="blue-span">{{scope.row.custCount}}<br></span>
        </template>
      </el-table-column>
      <el-table-column prop="manager" label="返佣规则">
        <template slot-scope="scope">
            <span
                @click="toRuleList(scope.row)"
                :class="{'blue-span': scope.row.linkType == 1}"
            >{{getRuleCount(scope.row)}}<br></span>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="updateTime" />
      <el-table-column width="400" label="链接" prop="link" />
      <el-table-column width="120" label="操作">
        <template slot-scope="scope">
          <span
              v-if="checkPermission(['ADMIN','CUST_LINK_ALL','CUST_LINK_EDIT']) && getEditShow(scope.row)"
          >
            <el-button
                @click="editHandle(scope.row)"
              type="success"
              size="mini"
            >编辑</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <rule-dialog
      :dialog-show="ruleDialogShow"
      :ib-lvl-id="currentRowId"
      @close="ruleDialogShow = false"
    ></rule-dialog>
    <add-links
        :dialog-show="addLinksDialogShow"
        @close="addLinksDialogShow = false"
        @do-success="doSuccess"
    ></add-links>
    <edit-links
        ref="addoreditcustomerlink"
      :dialog-show="editDialogShow"
      :agency-name="agencyName"
      @close="editDialogShow = false"
      :form-data="formData"
      :cust-id="custId"
      @do-success="editSuccess"
    ></edit-links>
    <edit-merchant-links
        ref="editmerchantlinks"
        :dialog-show="editMerchantLinksDialogShow"
        :form-data="editMerchantFormData"
        @close="editMerchantLinksClose"
        @do-success="editMerchantSuccess"
    ></edit-merchant-links>
  </div>
</template>

<script>
import editMerchantLinks from "@/components/ib/merchantinfo/editMerchantLinks";
import addLinks from '@/components/ib/merchantinfo/addLinks'
import editLinks from '@/components/ib/merchantinfo/editLinks.vue'
import ruleDialog from '@/components/ib/merchantLevel/ruleDialog'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/ib/merchantLevel/header'

export default {
  name:'merchanLevel',
  components: {
    eHeader,
    ruleDialog,
    editLinks,
    addLinks,
    editMerchantLinks,
  },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      query:{
        createType: '',
        custInfo: ''
      },
      ruleDialogShow: false,
      currentRowId: 0,
      // 添加链接 todo
      addLinksDialogShow: false,

      editDialogShow: false,
      formData:{
        name: '',
        stdName: '',
        proName: '',
        ecnName: '',
        defaultGroup: '',
      },
      agencyName: '',
      custId: '',


      editMerchantLinksDialogShow: false,
      editMerchantFormData:{
        id: '',
        custId: '',
        levelName: '',
        modifyRuleFlag: 1,
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    doAddMerchantSuccess(){
      this.page = 0;
      this.init();
    },
    getEditShow(row){
      // 系统创建的不能编辑
      if(row.custId === 0 && row.linkType === 1){
        return false;
      }else {
        // return row.linkType == 2 && !row.foreignConfig;
        // 其他的可以
        return true;
      }
    },

    addCustomerlink(){
      this.addLinksDialogShow = true;
    },
    // 添加链接成功
    doSuccess(){
      this.addLinksDialogShow = false;
      this.page = 1;
      this.init();
    },

    editHandle(row){

      this.agencyName = row.custName;
      this.formData.name = row.levelName;
      this.formData.linkId = row.id;
      this.custId = row.custId;
      if(row.linkType == 2){
        this.editDialogShow = true;
      }else if(row.linkType == 1){
        // 代理编辑
        this.editMerchantFormData.custId = row.custId;
        this.editMerchantFormData.levelName = row.levelName;
        this.editMerchantFormData.id = row.id;
        this.editMerchantFormData.modifyRuleFlag = row.modifyRuleFlag;
        this.editMerchantLinksDialogShow = true;
        // 赋值
        /*console.log(this.$refs.editmerchantlinks);
        console.log(this.$refs.editmerchantlinks.$refs.merchantlinkform);
        this.$refs.editmerchantlinks.$refs.merchantlinkform.form.agencyId = row.custId;
        this.$refs.editmerchantlinks.$refs.merchantlinkform.form.levelName = row.levelName;
        // 代理远程查询
        this.$refs.editmerchantlinks.$refs.merchantlinkform.getAgencyNameOption(row.custId);*/

      }
    },
    editMerchantLinksClose(){
      this.editMerchantLinksDialogShow = false;
    },
    editMerchantSuccess(){
      this.editMerchantLinksDialogShow = false;
    },
    beforeInit() {
      this.url = 'crm/getInvitationList';
      this.params = {
        page: this.page,
        size: this.size,
        createType: this.query.createType,
        custInfo: this.query.custInfo,
        linkType: this.query.linkType,
      }
      return true
    },
    clear(){
      this.query.createType = '';
      this.query.custInfo = '';
      this.init();
    },
    getType(item){
      return item.createType == 1 ? '系统创建' : '代理创建';
    },
    getLinkType(item){
      return item.linkType == 1 ? '代理链接': '直客链接';
    },
    toList(row){

      if(row.linkType == 1){
        this.$store.commit('setMerchantCustLvl', row.id);
        this.$router.push({path: '/pt/ib/merchantinfo'});
      }else {
        this.$store.commit('setCusmgrCustLvl', row.id);
        this.$router.push({path: '/pt/customer/cusmgr'});
      }
    },
    toRuleList(row){
      // this.$router.push({path: '/pt/bus/commrule',query:{ibLvl: row.id}});
      if(row.linkType == 1){
        this.currentRowId = row.id;
        this.ruleDialogShow = true;
      }
    },
    getRuleCount(row){
      if(row.linkType == 1){
        return row.ruleCount;
      }else {
        return 0;
      }
    },
    editSuccess(row){
      this.editDialogShow = false;
      this.init();
    }
  }
}
</script>

<style scoped lang="less">
  .blue-span {
    color: blue;
    cursor: pointer;
  }
</style>
